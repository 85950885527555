<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="caseName"
                    label="场景名称"
                    min-width="100"
                />
                <el-table-column
                    prop="caseDesc"
                    label="场景描述"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template
                        slot-scope="scope"
                    >
                        {{ scope.row.isAvailable == null ? '未设置' : scope.row.isAvailable == 1 ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="caseName"
                    label="成长值场景"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.caseName"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="caseCode"
                    label="成长值代码"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.caseCode"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="caseDesc"
                    label="成长值描述"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.caseDesc"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="exchangeRatio"
                    label="兑换比例"
                    label-width="7em"
                    key="exchangeRatio"
                    v-if="addDialog.formModel.caseCode !== 'distributorSubordinateOrder'"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.ruleConfig.exchangeRatio"
                    />
                    1元=？成长值
                </el-form-item>
                <el-form-item
                    prop="exchangeRatio1"
                    label="兑换比例"
                    label-width="7em"
                    key="exchangeRatio1"
                    v-else
                >
                    1元=？成长值
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="addDialog.ruleConfig.exchangeRatioList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="name"
                            label="分销层级"
                            width="120"
                        />
                        <el-table-column
                            prop="exchangeRatio"
                            label="默认比例"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.exchangeRatio"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="(item,index) in distributorLevelList"
                            :key="index"
                            :label="item.levelName + ' 兑换比例'"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :precision="2"
                                    v-model.number="scope.row.distributorLevelRateList[index].exchangeRatio"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="7em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GrowthValueRule',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    growthValueCaseId: '',
                    ruleConfig: '',
                    isAvailable: true,
                    caseName: '',
                    caseCode: '',
                    caseDesc: '',
                },
                // 表单校验规则
                formRules: {},
                ruleConfig: {
                    exchangeRatio: '',
                    exchangeRatioList: [],
                },
            },
            // 表格数据
            tableData: [],
            distributorLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.GrowthValueRule.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Mb.GrowthValueRule.getDetail({ growthValueCaseId: row.growthValueCaseId }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                this.addDialog.ruleConfig = JSON.parse(formModel.ruleConfig || null) || {};
                if (!this.addDialog.ruleConfig.exchangeRatioList) this.addDialog.ruleConfig.exchangeRatioList = [];
                this.initExchangeRatioList();
            });
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.GrowthValueRule.save({
                    ...this.addDialog.formModel,
                    ruleConfig: JSON.stringify(this.addDialog.ruleConfig),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
        initExchangeRatioList() {
            const newExchangeRatioList = [];
            for (let num = 1; num <= this.userData.shop.distributionLevel; num += 1) {
                let isExit = false;
                this.addDialog.ruleConfig.exchangeRatioList.forEach(item => {
                    if (item.id === num) {
                        isExit = true;
                        newExchangeRatioList.push(item);
                    }
                });
                if (!isExit) {
                    const exchangeRatio = {};
                    exchangeRatio.id = num;
                    exchangeRatio.name = `${num}级分销商`;
                    newExchangeRatioList.push(exchangeRatio);
                }
            }
            newExchangeRatioList.sort((a, b) => a.id - b.id);
            newExchangeRatioList.forEach(item => {
                if (!item.distributorLevelRateList) item.distributorLevelRateList = [];
                const distributorLevelRateList = [];
                this.distributorLevelList.forEach(item1 => {
                    let isExit = false;
                    item.distributorLevelRateList.forEach(item2 => {
                        if (item1.id === item2.id) {
                            isExit = true;
                            distributorLevelRateList.push(item2);
                        }
                    });
                    if (!isExit) {
                        distributorLevelRateList.push({ id: item1.id, exchangeRatio: undefined });
                    }
                });
                item.distributorLevelRateList = distributorLevelRateList;
            });
            this.addDialog.ruleConfig.exchangeRatioList = [...newExchangeRatioList];
        },
    },
    created() {
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
